.mainLayout-container{
    width: 92%;
    height: 100%;
    margin-left: 4%;
    background-color: aqua;
}

.header-container {
    text-align: center;
    background-color: darkseagreen;
}

.footer {
    height: 7em;
    padding: 0.5em 2em;
}

.content-container {
    min-height: calc(100vh - 13.2em);
}